<template>
  <div class="w-full">
    <div class="flex items-center">
      <div class="w-3/5 rounded-10 h-auto bg-white p-6">
        <div class="text-90 font-c6 text-c16 text-left">
          CHIFFRE D'AFFAIRE MENSUEL
        </div>

        <div class="mt-3">
          <divider
            height="0.8px"
            color="#DDDDDD"
          />
        </div>

        <div class="mt-4">
          <apexchart
            width="590"
            type="bar"
            :options="chartOptions"
            :series="series"
          />
        </div>
      </div>

      <div class="w-2/5">
        <div class="ml-8 rounded-10 h-auto bg-white flex p-6 pb-6">
          <div class="w-full text-left">
            <div class="text-c16 text-60 font-c3">
              Mes engagements
            </div>
            <div class="text-c24 font-c5 mt-2">
              240 000 <span class="text-90">FCFA</span>
            </div>
          </div>
        </div>

        <div class="ml-8 rounded-10 h-auto bg-white flex p-6 mt-3">
          <div class="w-full text-left">
            <div class="text-c16 text-60 font-c3">
              ACTIVITÉ
            </div>
            <div class="mt-3">
              <divider
                height="0.8px"
                color="#DDDDDD"
              />
            </div>

            <div class="flex mt-4">
              <div class="w-2/3">
                <div class="text-c16 font-c5">
                  Nouveau Devis #23839
                </div>
                <div class="text-c12 text-90 mt-2">
                  Par <span class="text-oho-blue-clear">Augustin TENOR</span>
                </div>
              </div>

              <div class="w-1/3 flex justify-end">
                <div>
                  <div class="text-c11 text-right text-60">
                    12/04/2021
                  </div>
                  <div class="text-c9 text-60 mt-1 text-right">
                    12h23min
                  </div>
                </div>
              </div>
            </div>

            <div class="mt-4">
              <divider
                height="0.8px"
                color="#DDDDDD"
              />
            </div>

            <div class="flex mt-4">
              <div class="w-2/3">
                <div class="text-c16 font-c5">
                  Facture #23839
                </div>
                <div class="text-c12 text-90 mt-2">
                  Par <span class="text-oho-blue-clear">Augustin TENOR</span>
                </div>
              </div>

              <div class="w-1/3 flex justify-end">
                <div>
                  <div class="text-c11 text-right text-60">
                    12/04/2021
                  </div>
                  <div class="text-c9 text-60 mt-1 text-right">
                    12h23min
                  </div>
                </div>
              </div>
            </div>

            <div class="mt-4">
              <divider
                height="0.8px"
                color="#DDDDDD"
              />
            </div>

            <div class="flex mt-4">
              <div class="w-2/3">
                <div class="text-c16 font-c5">
                  Nouveau client enregistré....
                </div>
                <div class="text-c12 text-90 mt-2">
                  Par <span class="text-oho-blue-clear">Augustin TENOR</span>
                </div>
              </div>

              <div class="w-1/3 flex justify-end">
                <div>
                  <div class="text-c11 text-right text-60">
                    12/04/2021
                  </div>
                  <div class="text-c9 text-60 mt-1 text-right">
                    12h23min
                  </div>
                </div>
              </div>
            </div>

            <div class="mt-4">
              <divider
                height="0.8px"
                color="#DDDDDD"
              />
            </div>

            <div class="mt-4 w-3/5 mr-auto ml-auto">
              <bouton
                height="28px"
                label="VOIR TOUTE L’ACTIVITÉ"
                background="#F5F5F5"
                color="black"
                size="10px"
                radius="14px"
                weight="300"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import divider from '../../helper/add/divider'
import bouton from '../../helper/add/button'

export default {
  name: "Index",
  components: {
    divider,
    bouton
  },
  data () {
    return {
      series: [{
        name: 'Objectifs',
        data: [12, 55, 57, 56]
      }, {
        name: 'Réalisations',
        data: [76, 85, 141, 98]
      }],
      chartOptions: {
        chart: {
          type: 'bar',
          height: 350
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '95%',
            endingShape: 'rounded'
          },
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent']
        },
        xaxis: {
          categories: ['JAN', 'FEV', 'MAR', 'AVR'],
        },
        yaxis: {
          title: {
            text: ''
          }
        },
        fill: {
          opacity: 1,
          colors: ['#B4E4C9', '#FFD0B1']
        },
        legend: {
          position: 'top',
        }
      },
    }
  },
  mounted() {
  },
  methods: {
  }
}
</script>

<style scoped>

</style>
