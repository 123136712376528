<template>
  <div class="">
    <div class="flex items-center">
      <div class="w-full rounded-10 h-auto bg-white p-6">
        <div class="mt-4 flex">
          <div
            class="w-1/3"
            @click="activeDetail = false"
          >
            <apexchart
              width="350"
              type="pie"
              :options="chartOptions"
              :series="series"
            />
          </div>

          <div class="w-2/3 flex ml-12">
            <div
              class="w-2/3 mt-12"
              @click="activeDetail = false"
            >
              <div class="text-c20 font-c3 text-left">
                Produits les plus vendus par catégorie
              </div>

              <div class=" mt-6">
                <div class="flex items-center">
                  <div class="w-22 mr-4">
                    <bouton
                      height="6px"
                      background="#2B9E7C"
                      radius="3px"
                      label=""
                    />
                  </div>
                  <div class="text-c14 text-90 w-full font-c3 text-left">
                    Verres <span class="font-c5 text-black"> 90 </span> (43.9%)
                  </div>
                </div>

                <div class="flex items-center mt-4">
                  <div class="w-22 mr-4">
                    <bouton
                      height="6px"
                      background="#F4B141"
                      radius="3px"
                      label=""
                    />
                  </div>
                  <div class="text-c14 text-90 w-full font-c3 text-left">
                    Etuis <span class="font-c5 text-black"> 60 </span> (29.3%)
                  </div>
                </div>

                <div class="flex items-center mt-4">
                  <div class="w-22 mr-4">
                    <bouton
                      height="6px"
                      background="#F9C49D"
                      radius="3px"
                      label=""
                    />
                  </div>
                  <div class="text-c14 text-90 w-full font-c3 text-left">
                    Montures <span class="font-c5 text-black"> 40 </span> (19.5%)
                  </div>
                </div>

                <div class="flex items-center mt-4">
                  <div class="w-22 mr-4">
                    <bouton
                      height="6px"
                      background="#5138EE"
                      radius="3px"
                      label=""
                    />
                  </div>
                  <div class="text-c14 text-90 w-full font-c3 text-left">
                    Cordes <span class="font-c5 text-black"> 15 </span> (7.3%)
                  </div>
                </div>
              </div>
            </div>

            <div class="w-1/3 pr-6">
              <div class="w-full">
                <div class="flex justify-end">
                  <div
                    class="filtre flex items-center justify-center"
                    @click="activeDetail =! activeDetail"
                  >
                    <div
                      class="flex items-center cursor-pointer"
                      @click="continuer"
                    >
                      <icon
                        v-if="icon !== null"
                        :data="icons.agenda"
                        height="20"
                        width="15"
                        class="mr-3"
                        original
                      />
                      <div class="mr-3">
                        <span> {{ filtreValue.substr(0, 7) }}</span> <span v-if="filtreValue.length > 7">...</span>
                      </div>
                      <icon
                        :data="icons.down"
                        height="20"
                        width="10"
                        original
                      />
                    </div>
                  </div>
                </div>

                <select-liste
                  v-if="activeDetail"
                  :option="optionFiltre"
                  @info="retourDetail"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import bouton from '../../helper/add/button'
import agenda from '../../../assets/icons/agenda.svg'
import down from '../../../assets/icons/down.svg'
import selectListe from '../../helper/form/selectFiltre'

export default {
  name: "Index",
  components: {
    bouton,
    selectListe
  },
  data () {
    return {
      icons: {
        agenda,
        down
      },
      activeDetail: false,
      filtreValue: 'Ce mois',
      optionFiltre: ['Ce mois', '6 Derniers mois', '12 Derniers mois'],
      series: [90, 60, 40, 15],
      chartOptions: {
        chart: {
          width: 380,
          type: 'pie',
        },
        grid: {
          show: false,
        },
        labels: ['Verres', 'Etuis', 'Montures', 'Cordes'],
        legend: {
          show: false,
        },
        fill: {
          colors: ['#2B9E7C', '#F4B141', '#F9C49D', '#5138EE']
        },
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              show: false,
            }
          }
        }]
      }
    }
  },
  methods: {
    retourDetail(answer) {
      this.activeDetail = false
      this.filtreValue = answer
    }
  }
}
</script>

<style scoped>
.svg-icon{
  fill: transparent;
}
.filtre{
  background: rgba(242, 245, 249, 0.5);
  border: 0.5px solid #C4C4C4;
  box-sizing: border-box;
  border-radius: 5px;
  height: 36px;
  width: 120px;
}
</style>
