<template>
  <div
    class="cedric-divider w-full"
    :style="{'background': color, 'height': height }"
  />
</template>

<script>export default {
  name: 'CedricDivider',
  props: {
    height: {
      type: String,
      default: '.1rem'
    },
    color: {
      type: String,
      default: '#000'
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/styles/sass/variables";

</style>
